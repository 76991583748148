import React from "react";
import Header from "../components/header/header.js";
import CategoryButtons from "../components/categoryButtons/categoryButtons";
import Track from "../assets/img/svg/track2.svg";

const Tracking = () => {
  return (
    <>
      <Header
        title="Tracciabilità dei dati"
        description={`Con l’aumentare dell’interconnessione nel mondo digitale, acquista un peso sempre maggiore il tema della sicurezza.`}
        categoryIcon={{ icon: Track, alt: "Tracciabilità dei dati Banner" }}
        metaDescription="Con l’aumentare dell’interconnessione nel mondo digitale, acquista un peso sempre maggiore il tema della sicurezza."
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <h2>
              È compliant al nuovo GDPR Regolamento (UE) 2016/679.
            </h2>
            <p className="description">
              <br />
              <br />
              Navigando in Internet si può incorrere in nuovi pericoli che
              rappresentano una minaccia per le aziende, ma anche per i privati
              e che a volte possono addirittura arrecare gravi danni. Il tema
              della sicurezza informatica è perciò attuale come mai prima d’ora,
              ma non riguarda solo la sicurezza su Internet, bensì si occupa
              anche di tutti gli altri aspetti e settori dell’Information
              Technology.
              <br />
              <br /> Connect@You da sempre conforme alle normative in materia di
              sicurezza, prima con il Decreto Pisanu, oggi con il GDPR entrato
              in vigore nel 2018.
              <br />
              <br />
              Il titolare di una rete che offre un servizio di connessione ad
              internet a terzi, si espone a dei rischi.
              <br />
              In caso di violazioni o attività illecite perpetrate tramite la
              rete della struttura, risulterà essere responsabile il
              proprietario, in sede civile e/o penale, a meno che non sia in
              grado di dimostrare che tali attività o violazioni siano state
              commesse da terzi.
            </p>
          </div>
        </div>
      </div>

      <div className="section" />
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <h3>Data protection</h3>
        </div>
      </div>
      <div className="section" />
      <div className="container">
        <div className="columns">
          <div className="column">
            <span className="azure">Come può aiutarti Connect@You?</span>
          </div>
          <div className="column">
            <p className="description">
              Garantendo la conservazione dei dati e la tracciabilità dei
              clienti.
              <br />
              <br />
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <p className="description">
              Pertanto è necessario che i titolari di dati personali raccolti in
              forma elettronica tramite l’autenticazione dei clienti attraverso
              la rete WiFi della propria struttura siano conformi al GDPR, così
              da adottare le misure atte a proteggere i dati sensibili e a
              gestirli secondo i criteri indicati dalla normativa vigente.
              <br />
              <br />
              Grazie a Connect@You garantisci ai tuoi clienti una connessione
              WiFi gratuita, facile e sicura e ti senti sollevato da ogni
              responsabilità!
            </p>
          </div>
        </div>
      </div>
      <div className="section" />
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <p className="center">Ti potrebbe interessare...</p>
        </div>
      </div>
      <div className="section" />
      <CategoryButtons titlePage="Tracciabilità dei dati" />
    </>
  );
};

export default Tracking;
